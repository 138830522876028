import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Footer, Header, SEO } from "../components"
import "./programs.scss"

export default () => {
  const [isHovering, setHovering] = useState(false)

  const {
    allMarkdownRemark: { nodes },
  } = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        sort: { order: DESC, fields: frontmatter___date }
        filter: { frontmatter: { path: { nin: ["/about", "/upcoming"] } } }
      ) {
        nodes {
          id
          frontmatter {
            path
            title
            date(formatString: "DD.MM.YYYY")
            type
            thumbnail
            description
          }
        }
      }
    }
  `)

  const hoverHandler = (e, i) => {
    if (e.type === "mouseenter") setHovering(i)
    else setHovering(null)
  }

  return (
    <div id="programs">
      <SEO title="Discrit" description="Art in Conversation" />

      <Header />
      <main>
        {nodes.map(({ frontmatter: el }, i) => (
          <article key={el.title}>
            <div className="img-container">
              <img
                className="bg-blur"
                src={el.thumbnail}
                style={{ opacity: isHovering === i ? 1 : 0 }}
              />
              <a href={`/programs/${el.path}`}>
                <img
                  onMouseEnter={e => hoverHandler(e, i)}
                  onMouseLeave={e => hoverHandler(e, i)}
                  src={el.thumbnail}
                  alt={el.title}
                />
              </a>
            </div>
            <div className="info-container" key={el.title}>
              <div className="date-type">
                <h2>{el.date}</h2>
                <p>{el.type}</p>
              </div>
              <a href={`/programs/${el.path}`}>
                <h1
                  onMouseEnter={e => hoverHandler(e, i)}
                  onMouseLeave={e => hoverHandler(e, i)}
                >
                  {el.title}
                </h1>
              </a>
              <p>{el.description}</p>
            </div>
          </article>
        ))}
      </main>
      <Footer />
    </div>
  )
}
